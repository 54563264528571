import Swiper, { Navigation, Pagination } from 'swiper';

const heroSlider = document.getElementById('js-sponsor-slider');

const heroSlide = new Swiper(heroSlider, {
    slidesPerView: 2,
    spaceBetween: 32,
    modules: [Navigation, Pagination],
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation: {
        prevEl: '.sponsor__arrow-left',
        nextEl: '.sponsor__arrow-right',
    },
    breakpoints: {
        1200: {
            slidesPerView: 2,
        },
        776: {
            slidesPerView: 1.2,
            spaceBetween: 16,
        },
        400: {
            slidesPerView: 1.2,
            spaceBetween: 16,
        },
        360: {
            slidesPerView: 1.2,
            spaceBetween: 16,
        },
        300: {
            slidesPerView: 1,
            spaceBetween: 16,
        },
    },
});
